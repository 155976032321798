import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/NavBar";
import { Products } from "../components/Products";
import { Helmet } from "react-helmet";

const capitalizeFirstLetter = (slug) => {
  return slug
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const Shop = () => {
  const { categoryId, catergorySlug } = useParams();
  const formattedCategory = catergorySlug
    ? capitalizeFirstLetter(catergorySlug)
    : "";
  return (
    <>
      <Helmet>
        <title>
          {formattedCategory
            ? `Shop ${formattedCategory} - TheMonkeySoul`
            : "Shop All Categories - TheMonkeySoul"}
        </title>
        <meta
          name="description"
          content="Shop the best at TheMonkeySoul. Explore trendy and stylish options for your wardrobe"
        />
        <meta
          name="keywords"
          content="trendy, online shopping, TheMonkeySoul, fashion, online shopping, trendy clothing, men's fashion, women's fashion, TheMonkeySoul"
        />
        <link
          rel="canonical"
          href={`https://themonkeysoul.com/product/${categoryId}/${catergorySlug}`}
        />
      </Helmet>

      <Navbar />
      <Products categoryId={categoryId} catergorySlug={catergorySlug} />
      <Footer />
    </>
  );
};
