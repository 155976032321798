// ProductOverviewSkeleton.js
import React from "react";
import "react-loading-skeleton/dist/skeleton.css"; // Skeleton CSS styles
import Skeleton from "react-loading-skeleton";
import "./skeleton.css"; // Import the custom CSS

export const SliderSkeleton = () => {
  return (
    <div className="main-overview-skeleton">
      <Skeleton className="skeleton" width="100%" height="80vh" />
    </div>
  );
};
