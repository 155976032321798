import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Typography, Button, Container, Paper } from "@mui/material";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { apiConfig } from "../utils/apiConfig";
import Confetti from "react-confetti"; // Import Confetti

const OrderConfirmation = () => {
  const [windowDimensions, setWindowDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const clearCart = async () => {
      await apiConfig.clearCart();
    };
    clearCart();

    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Order Confirmation - TheMonkeySoul</title>
        <meta
          name="description"
          content="Thank you for your order! We are processing it and will notify you when it’s ready for shipping."
        />
        <meta name="robots" content="noindex, nofollow" />
        <link
          rel="canonical"
          href="https://themonkeysoul.com/order-confirmation"
        />
      </Helmet>

      <Confetti
        width={windowDimensions.width}
        height={windowDimensions.height}
        recycle={false}
        numberOfPieces={300}
      />

      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Navbar />

        <Container
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: { xs: "100px", md: "80px" },
            marginBottom: { xs: "30px", md: "80px" },
            paddingX: { xs: 2, sm: 4 },
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: { xs: 2, sm: 4 },
              maxWidth: "90%",
              textAlign: "center",
              borderRadius: 2,
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              🎉 Congratulations!
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: { xs: "1rem", sm: "1.2rem" },
                marginBottom: { xs: 1, sm: 2 },
              }}
            >
              Your order has been placed successfully.
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: { xs: "0.875rem", sm: "1rem" },
                marginBottom: { xs: 2, sm: 3 },
              }}
            >
              We are processing your order and will notify you when it’s ready
              for shipping.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/"
              sx={{
                marginTop: { xs: 1, sm: 2 },
                textTransform: "none",
                padding: { xs: "8px 16px", sm: "10px 20px" },
                fontSize: { xs: "0.875rem", sm: "1rem" },
              }}
            >
              Go to Home
            </Button>
          </Paper>
        </Container>

        <Footer />
      </Box>
    </>
  );
};

export default OrderConfirmation;
