export const helper = {
  transformSlug: function (slug) {
    return slug
      .split("-") // Split the slug into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join words with spaces
  },
};

export function formatToIST(utcDateString) {
  // Check if the input date string is valid
  if (!utcDateString) {
    console.error("Invalid date input: ", utcDateString);
    return "Invalid Date";
  }

  const date = new Date(utcDateString);

  if (isNaN(date.getTime())) {
    console.error("Invalid date format: ", utcDateString);
    return "Invalid Date";
  }

  // Format options
  const options = {
    timeZone: "Asia/Kolkata", // IST Timezone
    year: "numeric",
    month: "long", // Full month name
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // 12-hour format with AM/PM
  };

  // Convert and format the date
  return new Intl.DateTimeFormat("en-IN", options).format(date);
}
