import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
} from "@mui/material";
import {
  Logout,
  LocationOn,
  AccountCircle,
  ShoppingCart,
} from "@mui/icons-material";
import InboxIcon from "@mui/icons-material/Inbox";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import { MyAccountOverview } from "../components/MyAccount/MyAccountOverview";
import OrderHistory from "../components/MyAccount/OrderHistory";
import AddressManager from "../components/MyAccount/AddressManager";
import Profile from "../components/MyAccount/Profile";
import { AddAddress } from "../components/MyAccount/AddAddress";
import MoreDetails from "../components/MyAccount/MoreDetails";
import { useDispatch } from "react-redux";
import { clearCart } from "../store/actions/cartActions";
import { Helmet } from "react-helmet"; // Import react-helmet

const MyAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [selectedMenu, setSelectedMenu] = useState("Overview");

  const menuItems = useMemo(
    () => [
      { icon: <InboxIcon />, text: "Overview", route: "/my-account" },
      {
        icon: <ShoppingCart />,
        text: "My Orders",
        route: "/my-account/orders",
      },
      {
        icon: <LocationOn />,
        text: "My Addresses",
        route: "/my-account/addresses",
      },
      {
        icon: <AccountCircle />,
        text: "My Profile",
        route: "/my-account/profile",
      },
      {
        icon: <Logout sx={{ color: "red" }} />,
        text: "Logout",
        route: "logout",
        action: () => {
          localStorage.clear(); // Clear local storage
          dispatch(clearCart()); // Clear the cart using Redux
          navigate("/"); // Navigate to the home screen
        },
      },
    ],
    [dispatch, navigate] // Dependencies for useMemo
  );

  const menuItemsMobile = [
    {
      icon: <ShoppingCart />,
      text: "My Orders",
      route: "/my-account/mobile-view/orders",
    },
    {
      icon: <LocationOn />,
      text: "My Addresses",
      route: "/my-account/mobile-view/addresses",
    },
    {
      icon: <AccountCircle />,
      text: "My Profile",
      route: "/my-account/mobile-view/profile",
    },
    {
      icon: <Logout sx={{ color: "red" }} />,
      text: "Logout",
      route: "logout",
      action: () => {
        localStorage.clear(); // Clear local storage
        dispatch(clearCart());
        navigate("/"); // Navigate to the home screen
      },
    },
  ];

  // Dynamically set the page title based on the selected menu
  const getPageTitle = () => {
    switch (location.pathname) {
      case "/my-account":
        return "My Account - Overview";
      case "/my-account/orders":
        return "My Account - My Orders";
      case "/my-account/addresses":
        return "My Account - My Addresses";
      case "/my-account/profile":
        return "My Account - My Profile";
      case "/my-account/addresses/add":
        return "My Account - Add Address";
      case "/my-account/order/moreInfo":
        return "My Account - Order Details";
      default:
        return "My Account - TheMonkeySoul";
    }
  };

  useEffect(() => {
    // Update the selected menu based on the current path
    const activeItem = menuItems.find(
      (item) => item.route === location.pathname
    );
    if (activeItem) {
      setSelectedMenu(activeItem.text);
    } else {
      setSelectedMenu("");
    }
  }, [location.pathname, menuItems]);

  return (
    <>
      <Helmet>
        <title>{getPageTitle()}</title>
        <meta
          name="description"
          content="Manage your account details, orders, and more at TheMonkeySoul."
        />
        <meta name="robots" content="noindex, nofollow" />
        <link
          rel="canonical"
          href={`https://themonkeysoul.com${location.pathname}`}
        />
      </Helmet>

      <Navbar />
      <Box
        sx={{
          marginTop: "110px",
          marginBottom: "50px",
          marginX: { xs: "16px", md: "80px" },
        }}
      >
        {isMobile ? (
          // Mobile view layout
          <List>
            {menuItemsMobile.map((item, index) => (
              <ListItem
                key={index}
                button
                onClick={() => {
                  setSelectedMenu(item.text);
                  if (item.action) {
                    item.action(); // Execute the logout action
                  } else {
                    navigate(item.route);
                  }
                }}
                sx={{
                  cursor: "pointer",
                  borderBottom: "1px solid #ddd",
                  padding: "12px 16px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box display="flex" alignItems="center">
                  {item.icon}
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      marginLeft: "8px",
                      fontWeight:
                        selectedMenu === item.text ? "bold" : "normal",
                    }}
                  />
                </Box>
                <Typography>&gt;</Typography>
              </ListItem>
            ))}
          </List>
        ) : (
          // Desktop view layout
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            {/* Side Navigation */}
            <Box
              sx={{
                width: { xs: "100%", md: "300px" },
                bgcolor: "#fff",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "20px",
                marginBottom: { xs: 2, md: 0 },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 3,
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                Overview
              </Typography>
              <List>
                {menuItems.map((item, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => {
                      setSelectedMenu(item.text);
                      if (item.action) {
                        item.action(); // Execute the logout action
                      } else {
                        navigate(item.route);
                      }
                    }}
                    sx={{
                      cursor: "pointer",
                      backgroundColor:
                        selectedMenu === item.text ? "#f5f5f5" : "transparent",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{
                        fontWeight:
                          selectedMenu === item.text ? "bold" : "normal",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>

            {/* Main Content */}
            <Box
              sx={{
                flexGrow: 1,
                paddingLeft: { xs: 0, md: "20px" },
                width: "100%",
              }}
            >
              {location.pathname === "/my-account" && <MyAccountOverview />}
              {location.pathname === "/my-account/orders" && <OrderHistory />}
              {location.pathname === "/my-account/addresses" && (
                <AddressManager />
              )}
              {location.pathname === "/my-account/profile" && <Profile />}
              {location.pathname === "/my-account/addresses/add" && (
                <AddAddress />
              )}
              {location.pathname === "/my-account/order/moreInfo" && (
                <MoreDetails />
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default MyAccount;
