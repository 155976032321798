const initialState = {
  products: {},
};

const productReducer = (state = initialState, action) => {
  console.log(action.type, "checking product");
  switch (action.type) {
    case "SAVE_PRODUCT_DETAILS":
      return {
        ...state, // Include the previous state
        productDetails: action.payload, // Save product name and URL
      };
    default:
      return state;
  }
};

export default productReducer;
