import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  Form,
  Alert,
} from "react-bootstrap";
import PercentIcon from "@mui/icons-material/Percent";
import Navbar from "../components/NavBar";
import PaymentIcon from "@mui/icons-material/Payment";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import VerifiedIcon from "@mui/icons-material/Verified";
import { apiConfig } from "../utils/apiConfig";
import { debounce } from "lodash";
import OrderModal from "../components/OrderModal";
import "./css/cart.css";
import Footer from "../components/Footer";
import { useDispatch } from "react-redux";
import { removeFromCart } from "../store/actions/cartActions";
import { Box, Snackbar, Typography } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Swal from "sweetalert2";
import { SizeSelectorModal } from "../components/SizeSelectorModal";
import { QuantitySelectorModal } from "../components/QuantitySelectorModal";
import CartSkeleton from "./skeleton/cartSkeleton";
import { CouponModal } from "../components/CouponModal";
import { Helmet } from "react-helmet"; // Import Helmet
import swal from "sweetalert2";

export const Cart = () => {
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);
  const dispatch = useDispatch();
  const [allDiscountCoupon, setAllDiscountCoupon] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [noProductError, setNoProductError] = useState("");
  const [discount, setDiscount] = useState(0); // State to hold the discount amount
  const [address, setAddress] = useState([]);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [originalTotal, setOriginalTotal] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);
  const [selectAddress, setSelectAddress] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isSelectorModalOpen, setIsSelectorModalOpen] = useState(false);
  const [isQuantitySelectorModalOpen, setIsQuantitySelectorModalOpen] =
    useState(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);

  const handlePlaceOrder = () => {
    setIsOrderModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsOrderModalOpen(false);
  };

  const handleDiscountOpenModal = (id) => {
    setSelectedItemId(id);
    setIsDiscountModalOpen(true);
  };
  const handleDiscountCloseModal = () => {
    setSelectedItemId(null);
    setIsDiscountModalOpen(false);
  };

  const handleQuantitySelectorOpenModal = (id) => {
    setSelectedItemId(id);
    setIsQuantitySelectorModalOpen(true);
  };
  const handleQuantitySelectorCloseModal = () => {
    setSelectedItemId(null);
    setIsQuantitySelectorModalOpen(false);
  };

  const handleSelectorOpenModal = (id) => {
    setSelectedItemId(id);
    setIsSelectorModalOpen(true);
  };
  const handleSelectorCloseModal = () => {
    setSelectedItemId(null);
    setIsSelectorModalOpen(false);
  };

  const handleDiscountCoupon = async () => {
    console.log(couponCode, "coupon Code");
    setIsDiscountModalOpen(false);
    if (!couponCode || !cart) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a coupon code",
      });
      return;
    }

    setLoading(true);

    try {
      const response = await apiConfig.applyDiscount({
        code: couponCode,
        purchaseAmount: calculateTotal(),
      });

      if (response.status === 200 && response.data.success) {
        const { discountAmount } = response.data;
        setDiscount(discountAmount);
        setIsDiscountModalOpen(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Coupon applied successfully!",
        });
      } else {
        setIsDiscountModalOpen(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Invalid coupon code",
        });
      }
    } catch (err) {
      setDiscount(0);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err.response?.data?.message || "Failed to apply coupon code",
      });
    } finally {
      setLoading(false);
    }
  };

  const updateCartItemDetails = useCallback(
    debounce(async (id, quantity, size) => {
      try {
        const response = await apiConfig.updateItemDetails(id, {
          quantity,
          size,
        });
        if (response.status === 200) {
          console.log("Cart updated successfully");
        } else {
          console.error("Failed to update cart:", response);
        }
      } catch (error) {
        console.error("Error updating cart:", error);
      }
    }, 2000),
    []
  ); // 3 seconds delay

  // Update quantity function
  const handleQuantityChange = (id, quantity) => {
    setCart(
      cart.map((item) =>
        item._id === id
          ? {
              ...item,
              quantity: Number(quantity),
            }
          : item
      )
    );
    console.log(cart, "cart");
    const updatedItem = cart.find((item) => item._id === id);

    updateCartItemDetails(id, quantity, updatedItem.size);
    setIsQuantitySelectorModalOpen(false);
  };

  const handleSizeChange = (id, newSize) => {
    setCart((prevItems) =>
      prevItems.map((item) =>
        item._id === id ? { ...item, size: newSize } : item
      )
    );
    const updatedItem = cart.find((item) => item._id === id);
    setIsSelectorModalOpen(false);
    updateCartItemDetails(id, updatedItem.quantity, newSize);
  };

  const handleSelectAddress = (id) => {
    console.log(id, "select Address");
    setSelectAddress(id);
  };

  // Remove item function
  const handleRemove = async (id) => {
    console.log(id, "checking");

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This product will be removed from your cart.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
      cancelButtonText: "No, keep it",
    });

    if (result.isConfirmed) {
      try {
        Swal.fire(
          "Removed!",
          "The product has been removed from your cart.",
          "success"
        );
        // Dispatch the removeFromCart action
        dispatch(removeFromCart(id));
        // Remove from local cart state (or redux state if needed)
        setCart(cart.filter((item) => item._id !== id));
        // Make API call to delete item from the backend/cart database
        await apiConfig.deleteItemFromCart(id);
        // Optionally show success message
      } catch (error) {
        // Optionally handle errors
        Swal.fire("Error", "Something went wrong. Please try again.", "error");
      }
    } else {
      // Optionally show a cancellation message
      Swal.fire("Cancelled", "Your product is still in the cart.", "info");
    }
  };

  const handleProceedOrder = async () => {
    const data = {
      user: cart?.[0].userId,
      address: selectAddress,
      // address: "672a59544a31b1d620dfcc1e",
      products: cart?.map((item) => ({
        product: item.productId._id,
        quantity: item.quantity,
        size: item.size,
      })),
      totalAmount: finalTotal,
      discountCode: couponCode,
      discountAmount: discount,
    };
    console.log(data, "data");

    try {
      let payment = await apiConfig.payment(data);
      console.log(payment, "check");
      payment = payment.data;
      if (
        payment &&
        payment.status === "success" &&
        payment.data.success &&
        payment.data.data.instrumentResponse &&
        payment.data.data.instrumentResponse.redirectInfo &&
        payment.data.data.instrumentResponse.redirectInfo.url
      ) {
        // Redirect to the payment URL
        window.location.href =
          payment.data.data.instrumentResponse.redirectInfo.url;
      } else {
        console.log("Payment initiation failed:", payment);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updatePricing = () => {
    const original = calculateOriginalTotal();
    const final = calculateTotal();
    const discountPercent = calculateDiscountPercentage(original, final);

    setOriginalTotal(original);
    setFinalTotal(final);
    setDiscountPercentage(discountPercent);
  };

  const calculateOriginalTotal = () =>
    cart.reduce((acc, item) => acc + item?.productId?.price * item.quantity, 0);

  // Calculate final total (after discount)
  const calculateTotal = () => {
    const originalTotal = cart.reduce(
      (acc, item) => acc + item?.productId?.sellingPrice * item.quantity,
      0
    );
    return originalTotal - discount;
  };

  // Calculate discount percentage
  const calculateDiscountPercentage = () => {
    const originalTotal = calculateOriginalTotal();
    const finalTotal = calculateTotal();
    return originalTotal > 0
      ? Math.round(((originalTotal - finalTotal) / originalTotal) * 100)
      : 0;
  };

  useEffect(() => {
    updatePricing();
  }, [cart, discount]);

  useEffect(() => {
    const fetchCart = async () => {
      try {
        const response = await apiConfig.allItems(); // Fetch product by ID
        if (response?.data?.status === "fail") {
          setNoProductError(true);
          setLoading(false);
          return;
        }
        const address = await apiConfig.getAddressesByUser();
        const discount = await apiConfig.allDiscount();
        setAllDiscountCoupon(discount?.data?.data);
        setCart(response?.data?.data); // Assuming response.data contains the product object
        setAddress(address?.data?.data);
        setLoading(false); // Set loading to false after fetching
      } catch (err) {
        console.error("Error fetching product:", err);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchCart();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {cart.length > 0 ? "My Cart - TheMonkeySoul" : "Your Cart is Empty"}
        </title>
        <meta
          name="description"
          content={
            cart.length > 0
              ? `You have ${cart.length} items in your cart. Ready to checkout?`
              : "Your cart is empty. Add items to proceed with your order."
          }
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Navbar />
        {loading ? (
          <CartSkeleton />
        ) : (
          <main style={{ flex: 1, marginTop: "110px" }}>
            <Container className="my-4" style={{ maxWidth: "1100px" }}>
              <h4>My Bag ({cart.length} Items)</h4>
              <Alert variant="light" className="border">
                Yay! You get <strong>FREE delivery</strong> on your First Order
              </Alert>

              <Row>
                <Col lg={8} md={7}>
                  {noProductError ? (
                    <Card className="p-3 mb-3">
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <ShoppingCartIcon
                            className="me-2"
                            style={{ fontSize: "1.5rem" }}
                          />
                          <span>Please add the Product</span>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    <Card className="p-3 mb-3">
                      {cart?.map((item) => (
                        <div className="card mb-3" style={{ padding: "10px" }}>
                          <div key={item.id} className="d-flex">
                            <div className="cart-prod-image">
                              <figure>
                                <img
                                  src={item?.productId?.primaryImageUrl}
                                  alt="Product"
                                  style={{ width: "100%", height: "auto" }}
                                />
                              </figure>
                            </div>

                            <div
                              className="ms-3 d-flex justify-content-between "
                              style={{ width: "100%" }}
                            >
                              <div style={{ flexGrow: 1 }}>
                                <p style={{ margin: "0", fontWeight: "600" }}>
                                  TheMonkeySoul(™)
                                </p>
                                <p style={{ color: "#737e93" }}>
                                  {item?.productId?.productName}
                                </p>
                                <p>
                                  <i class="fa-solid fa-truck-fast"></i> Ship in
                                  2-3 days
                                </p>
                              </div>

                              <div className="">
                                <p
                                  onClick={() => handleRemove(item._id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i class="fa-solid fa-xmark"></i>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex gap-2 mt-2">
                              <Form.Select
                                size="sm"
                                defaultValue={item?.size}
                                style={{
                                  width: "auto",
                                  height: "30px",
                                }}
                                onClick={() =>
                                  handleSelectorOpenModal(item._id)
                                }
                              >
                                <option>Size: {item?.size}</option>
                              </Form.Select>
                              <SizeSelectorModal
                                open={isSelectorModalOpen}
                                onClose={handleSelectorCloseModal}
                                onConfirm={(size) =>
                                  handleSizeChange(selectedItemId, size)
                                }
                              />
                              <Form.Select
                                size="sm"
                                defaultValue={item?.quantity}
                                style={{
                                  width: "auto",
                                  height: "30px",
                                }}
                                onClick={() =>
                                  handleQuantitySelectorOpenModal(item._id)
                                }
                              >
                                <option>Qty: {item?.quantity}</option>
                              </Form.Select>
                              <QuantitySelectorModal
                                open={isQuantitySelectorModalOpen}
                                onClose={handleQuantitySelectorCloseModal}
                                onConfirm={(quantity) =>
                                  handleQuantityChange(selectedItemId, quantity)
                                }
                              />
                            </div>
                            <div>
                              <div className="ms-auto text-end">
                                <span className="mb-0">
                                  <span style={{ fontWeight: "600" }}>
                                    ₹
                                    {parseInt(item?.productId?.sellingPrice) *
                                      parseInt(item?.quantity)}{" "}
                                  </span>
                                  <del
                                    style={{ color: "grey", fontSize: "12px" }}
                                  >
                                    ₹
                                    {parseInt(item?.productId?.price) *
                                      parseInt(item?.quantity)}
                                  </del>
                                </span>
                                <p
                                  className="text-success"
                                  style={{ fontSize: "12px" }}
                                >
                                  You saved ₹
                                  {parseInt(
                                    item?.productId?.price -
                                      item?.productId?.sellingPrice
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Card>
                  )}
                </Col>

                <Col lg={4} md={5}>
                  <Card
                    className="p-3"
                    variant="outlined"
                    sx={{
                      borderRadius: "8px",
                      borderColor: "rgba(0, 0, 0, 0.12)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", marginBottom: "8px" }}
                    >
                      Coupons & Offers
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PercentIcon
                          sx={{
                            color: "green",
                            marginRight: "8px",
                            fontSize: "18px",
                          }}
                        />
                        <Box>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              marginBottom: "4px",
                            }}
                          >
                            Apply Coupon / Gift Card
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "13px",
                              color: "rgba(0, 0, 0, 0.6)",
                            }}
                          >
                            Crazy deals and other amazing offers
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#207bb4",
                          fontSize: "11px",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={handleDiscountOpenModal}
                      >
                        VIEW
                      </Typography>
                      <CouponModal
                        couponCode={couponCode}
                        setCouponCode={setCouponCode}
                        handleDiscountCoupon={handleDiscountCoupon}
                        allDiscount={allDiscountCoupon}
                        open={isDiscountModalOpen}
                        onClose={handleDiscountCloseModal}
                        onConfirm={(quantity) =>
                          handleQuantityChange(selectedItemId, quantity)
                        }
                      />
                    </Box>
                  </Card>
                  <br />
                  <Card className="p-3">
                    <h5>PRICE SUMMARY</h5>
                    <p>
                      Total MRP (Incl. of taxes):{" "}
                      <span className="float-end">
                        ₹{parseInt(originalTotal)}
                      </span>
                    </p>
                    <p>
                      Bag Discount:{" "}
                      <span className="float-end text-danger">
                        -₹{parseInt(originalTotal - finalTotal)}
                      </span>
                    </p>
                    {!discount ? (
                      <></>
                    ) : (
                      <p>
                        Coupon Applied:{" "}
                        <span className="float-end text-danger">
                          -₹{parseInt(discount)}
                        </span>
                      </p>
                    )}
                    {/* <p>
                    Combo Offer Discount:{" "}
                    <span className="float-end text-danger">
                      -₹{priceSummary.comboDiscount}
                    </span>
                  </p> */}
                    <p>
                      Delivery Fee:{" "}
                      <span className="float-end text-success">Free</span>
                    </p>
                    <hr />
                    <h6>
                      Subtotal: <span className="float-end">₹{finalTotal}</span>
                    </h6>
                    <p className="text-success">
                      You are saving a total of ₹
                      {parseInt(originalTotal - finalTotal)} on this order
                    </p>
                    <Button
                      variant="warning"
                      className="w-100 mt-3"
                      onClick={handlePlaceOrder}
                    >
                      PROCEED
                    </Button>
                  </Card>
                </Col>
              </Row>

              <Row className="justify-content-around mt-4 text-center">
                <Col xs={4}>
                  <PaymentIcon />
                  <p>100% Secure Payment</p>
                </Col>
                <Col xs={4}>
                  <AutorenewIcon />
                  <p>Easy Returns & Instant Refunds</p>
                </Col>
                <Col xs={4}>
                  <VerifiedIcon />
                  <p>Quality Assurance</p>
                </Col>
              </Row>
            </Container>
            <Snackbar
              open={!!error}
              autoHideDuration={4000}
              onClose={() => setError("")}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert onClose={() => setError("")} severity="error">
                {error}
              </Alert>
            </Snackbar>
            <Snackbar
              open={!!success}
              autoHideDuration={4000}
              onClose={() => setSuccess("")}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert onClose={() => setSuccess("")} severity="success">
                {success}
              </Alert>
            </Snackbar>
          </main>
        )}

        <OrderModal
          open={isOrderModalOpen}
          onClose={handleCloseModal}
          addresses={address}
          setAddress={handleSelectAddress}
          handleProceedOrder={handleProceedOrder}
        />
        <Footer />
      </Box>
    </>
  );
};
