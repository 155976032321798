// ProductOverviewSkeleton.js
import React from "react";

import "../css/product-overview.css"; // Ensure CSS for styling

import "react-loading-skeleton/dist/skeleton.css"; // Skeleton CSS styles
import Skeleton from "react-loading-skeleton";

const ProductOverviewSkeleton = () => {
  return (
    <>
      <div className="product-overview-skeleton">
        <div className="row gx-5">
          <aside className="col-lg-6">
            <div className=" mb-3">
              <Skeleton height={600} width="100%" />
            </div>
            <div className="d-flex justify-content-center mb-3">
              {[...Array(5)].map((_, index) => (
                <Skeleton
                  key={index}
                  width={60}
                  height={60}
                  style={{ margin: "0 4px" }}
                />
              ))}
            </div>
          </aside>
          <main className="col-lg-6">
            {/* Product details */}
            <div className="ps-lg-3">
              <h4 className="title text-dark">
                <Skeleton
                  width={260}
                  height={40}
                  inline={true}
                  style={{ marginRight: "8px" }}
                />
              </h4>
              <Skeleton
                width={260}
                height={40}
                inline={true}
                style={{ marginRight: "8px" }}
              />

              <div className="mb-3">
                <span>
                  <span>
                    <Skeleton
                      width={260}
                      height={40}
                      inline={true}
                      style={{ marginRight: "8px" }}
                    />
                  </span>
                  &nbsp; &nbsp;
                  <span
                    style={{
                      textDecoration: "line-through",
                      marginRight: 5,
                      fontSize: "14px",
                      color: "grey",
                    }}
                  >
                    <Skeleton
                      width={260}
                      height={40}
                      inline={true}
                      style={{ marginRight: "8px" }}
                    />
                  </span>
                </span>
                <span style={{ color: "green", marginLeft: 10 }}>
                  <Skeleton
                    width={260}
                    height={40}
                    inline={true}
                    style={{ marginRight: "8px" }}
                  />
                </span>
              </div>

              <p>
                <Skeleton
                  width={260}
                  height={150}
                  inline={true}
                  style={{ marginRight: "8px" }}
                />
              </p>

              <hr />

              <div className="row mb-4">
                <div className="col-md-4 col-6">
                  {/* <label className="mb-2">Size</label> */}
                  <Skeleton
                    width={200}
                    height={40}
                    inline={true}
                    style={{ marginRight: "8px" }}
                  />
                </div>
                <div className="col-md-4 col-6">
                  {/* <label className="mb-2 d-block">Quantity</label> */}
                  <Skeleton
                    width={200}
                    height={40}
                    inline={true}
                    style={{ marginRight: "8px" }}
                  />
                </div>
              </div>
              <div className="mobile-sticky-footer">
                <Skeleton
                  width={260}
                  height={40}
                  inline={true}
                  style={{ marginRight: "8px" }}
                />
                &nbsp; &nbsp;
                <Skeleton
                  width={260}
                  height={40}
                  inline={true}
                  style={{ marginRight: "8px" }}
                />
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* <div className="product-overview-skeleton">
        <div className="d-flex justify-content-center mb-3">
          {[...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              width={60}
              height={60}
              style={{ margin: "0 4px" }}
            />
          ))}
        </div>
        <Skeleton width={200} height={30} />
        <Skeleton width={100} height={20} />
        <Skeleton width={100} height={30} style={{ marginTop: 10 }} />
        <Skeleton count={5} />
        <div className="row mb-4">
          <Skeleton width={170} height={35} style={{ margin: "0 auto" }} />
        </div>
        <Skeleton width={300} height={50} style={{ marginTop: 20 }} />
      </div> */}
    </>
  );
};

export default ProductOverviewSkeleton;
