import { Helmet } from "react-helmet"; // Import Helmet
import { Box } from "@mui/material";
import Footer from "../components/Footer";
import Navbar from "../components/NavBar";

export const Blogs = () => {
  return (
    <>
      <Helmet>
        <title>Blogs - TheMonkeySoul</title>
        <meta
          name="description"
          content="Our blog page is currently under construction. Check back soon for exciting content."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Navbar />
        <section style={{ flex: 1 }}>
          <div style={{ margin: "110px 2rem" }}>
            <div className="row">
              <div className="col-md-12">
                <h1 className="">Blogs</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h5>
                  Hello! Our blog page is currently under construction.
                  <br />
                  We appreciate your patience and understanding as we work to
                  bring you exciting content soon.
                  <br />
                  Thank you for stopping by!
                </h5>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Box>
    </>
  );
};
