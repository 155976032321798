import Footer from "../components/Footer";
import Navbar from "../components/NavBar";
import { Box, Typography, TextField, Button, IconButton } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import { useState } from "react";
import { useSelector } from "react-redux";
import { apiConfig } from "../utils/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet"; // Importing Helmet

export const AddProductRating = () => {
  const [rating, setRating] = useState(0); // Default rating
  const [file, setFile] = useState(null);
  const { productId } = useParams();
  const navigate = useNavigate();
  const [comment, setComment] = useState(""); // Review comment
  const productDetails = useSelector((state) => state.products.productDetails);
  console.log(productDetails, "ProductDetails");

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!comment) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please add a comment before submitting.",
      });
      return;
    }

    const reviewData = {
      productId, // Assuming `id` is the product ID
      rating,
      comment,
    };

    try {
      // Replace with your backend API endpoint
      const response = await apiConfig.addRating(reviewData);
      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Thank you!",
          text: "Your review has been submitted successfully!",
        }).then(() => {
          setComment(""); // Clear comment
          setRating(4); // Reset rating
          navigate(-1); // Redirect after user clicks "OK"
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Thank you!",
          text: "You have already rated this product!!",
        }).then(() => {
          setComment(""); // Clear comment
          setRating(4); // Reset rating
          navigate(-1); // Redirect after user clicks "OK"
        });
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "Failed to submit the review. Please try again.",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Add Product Rating - {productDetails.name}</title>
        <meta
          name="description"
          content={`Leave a review and rating for the product ${productDetails.name}. Share your experience and help others make an informed decision.`}
        />
        <meta
          name="keywords"
          content={`${productDetails.name}, product review, customer rating, feedback, TheMonkeySoul`}
        />
        <meta
          property="og:title"
          content={`Review for ${productDetails.name}`}
        />
        <meta
          property="og:description"
          content={`Leave a review and rating for the product ${productDetails.name} on TheMonkeySoul. Share your thoughts and feedback.`}
        />
        <meta
          property="og:image"
          content={productDetails.url} // Assuming you have an image URL for the product
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Navbar />
        <div style={{ flex: 1, marginTop: "100px", marginBottom: "50px" }}>
          <Box
            sx={{
              maxWidth: "1000px",
              margin: "auto",
              padding: "20px",
              textAlign: "center",
              // border: "1px solid #e0e0e0",
              // borderRadius: "8px",
            }}
          >
            {/* Product Details */}
            <Box display="flex" alignItems="center" gap={2} mb={2}>
              <img
                src={productDetails.url}
                alt={productDetails.name}
                style={{ width: "120px", height: "auto", borderRadius: "8px" }}
              />
              <Box textAlign="left">
                <Typography fontWeight="bold" fontSize="18px">
                  {productDetails.name}
                </Typography>
                <Box display="flex" alignItems="center">
                  {[...Array(5)].map((_, index) => (
                    <IconButton
                      key={index}
                      onClick={() => setRating(index + 1)}
                      sx={{ padding: 0 }}
                    >
                      {index < rating ? (
                        <StarIcon sx={{ color: "#FFD700" }} /> // Yellow stars
                      ) : (
                        <StarBorderIcon sx={{ color: "#FFD700" }} /> // Yellow outline for empty stars
                      )}
                    </IconButton>
                  ))}
                </Box>
                <Typography ml={1} color="green" fontWeight="bold">
                  {rating === 1 && "We're sorry to hear that."}
                  {rating === 2 && "We'll try to do better!"}
                  {rating === 3 && "Thanks for your feedback!"}
                  {rating === 4 && "Glad that you liked our product!"}
                  {rating === 5 && "Awesome! Thank you for your love!"}
                </Typography>
              </Box>
            </Box>

            {/* Text Area */}
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              // placeholder="Get up to Rs.100 in credits for sharing your review."
              placeholder="Share your review."
              onChange={(e) => setComment(e.target.value)}
              sx={{ marginBottom: "16px" }}
            />

            {/* Image Upload */}
            {/* <Box
              sx={{
                border: "1px dashed #ccc",
                padding: "12px",
                marginBottom: "16px",
                cursor: "pointer",
                textAlign: "center",
                borderRadius: "8px",
              }}
              onClick={() => document.getElementById("image-upload").click()}
            >
              <input
                type="file"
                id="image-upload"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              <InsertPhotoOutlinedIcon fontSize="large" color="primary" />
              <Typography>
                Add a few shots & show off your style. <br />
                Maximum file size: 5Mb
              </Typography>
              {file && (
                <Typography color="secondary" mt={1}>
                  {file.name}
                </Typography>
              )}
            </Box> */}

            {/* Shopping Credits Info */}
            {/* <Typography
              variant="caption"
              color="textSecondary"
              display="block"
              mb={2}
            >
              Get featured and stand a chance to win up to 50 shopping credits
              when you upload images using the product.
            </Typography> */}

            {/* Submit Button */}
            <Button
              variant="outlined"
              fullWidth
              onClick={handleSubmit}
              sx={{
                textTransform: "uppercase",
                fontWeight: "bold",
                padding: "12px",
                color: "black", // Black text
                border: "2px solid black", // Black border
                backgroundColor: "transparent", // Transparent background
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)", // Light black hover effect
                  borderColor: "black",
                },
              }}
            >
              Submit your feedback
            </Button>
          </Box>
        </div>
        <Footer />
      </Box>
    </>
  );
};
