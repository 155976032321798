import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  List,
  ListItem,
  Box,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const CouponModal = ({
  couponCode,
  setCouponCode,
  handleDiscountCoupon,
  allDiscount,
  open,
  onClose,
}) => {
  const [availableCoupons, setAvailableCoupon] = useState([]);

  const handleApplyCoupon = async (code) => {
    await setCouponCode(code); // Update the `couponCode` state with the selected coupon code
  };

  useEffect(() => {
    setAvailableCoupon(allDiscount);
  }, [allDiscount]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          margin: "10px", // Adds margin for smaller screens
          borderRadius: "10px", // Soft corners for a modern look
        },
      }}
    >
      {/* Header Section */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            margin: 0,
            lineHeight: "1.5",
          }}
        >
          Coupons & Offers
        </DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
        >
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Box>

      {/* Content Section */}
      <DialogContent
        sx={{
          padding: "15px 20px",
        }}
      >
        {/* Available Coupons */}
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: "8px",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          Available Coupons
        </Typography>
        <List>
          {availableCoupons.map((coupon) => (
            <ListItem
              key={coupon.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "8px",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "8px",
                padding: "8px",
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "13px", fontWeight: "bold" }}
                >
                  {coupon.code}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "12px" }}
                >
                  {coupon.description}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleApplyCoupon(coupon.code)}
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  padding: "4px 12px",
                  fontSize: "12px",
                  borderRadius: "6px",
                }}
              >
                Apply
              </Button>
            </ListItem>
          ))}
        </List>

        {/* Enter Coupon Code Section */}
        <Typography
          variant="subtitle1"
          sx={{
            margin: "16px 0 8px",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          Enter a Coupon Code
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Enter coupon code"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          sx={{
            marginBottom: "16px",
            fontSize: "14px",
            borderRadius: "8px",
          }}
        />
        <Button
          variant="contained"
          fullWidth
          onClick={handleDiscountCoupon}
          disabled={!couponCode.trim()}
          sx={{
            backgroundColor: "black",
            color: "white",
            fontWeight: "bold",
            padding: "10px",
            fontSize: "14px",
            height: "50px",
            "&:hover": {
              backgroundColor: "#333",
            },
            borderRadius: "8px",
          }}
        >
          Apply
        </Button>
      </DialogContent>
    </Dialog>
  );
};
