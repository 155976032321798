import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Snackbar,
  Alert,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { apiConfig } from "../../utils/apiConfig";
import { useMediaQuery } from "@mui/material";
import Navbar from "../NavBar";
import Footer from "../Footer";

const Profile = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    isPhoneVerified: "",
  });

  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [countdown, setCountdown] = useState(120); // 2 minutes in seconds
  const isMobile = useMediaQuery("(max-width: 790px)");

  // Fetch user data when component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiConfig.getUserById();
        setUserData(response?.data?.data);
      } catch (err) {
        setError("Failed to fetch user data");
      }
    };
    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const sendOtp = async () => {
    setError("");
    setSuccess("");
    try {
      const response = await apiConfig.sendOtp({ phoneNumber: userData.phone });
      setOtpSent(true);
      setSuccess("OTP sent successfully");
      setModalOpen(true); // Open modal for OTP verification
      setCountdown(120); // Reset countdown
    } catch (err) {
      setError(err.response?.data?.message || "Failed to send OTP");
    }
  };

  const verifyOtp = async () => {
    setError("");
    setSuccess("");
    try {
      const response = await apiConfig.verifyOtp({
        phoneNumber: userData.phone,
        otp: otp,
      });

      // Set isPhoneVerified to true in userData
      setUserData((prevData) => ({
        ...prevData,
        isPhoneVerified: true, // Update isPhoneVerified to true
      }));

      setOtp(""); // Reset OTP
      setModalOpen(false); // Close modal
      setOtpSent(false); // Reset OTP sent status
      setSuccess("Phone number verified successfully");

      // Pass the updated userData (with isPhoneVerified: true) to updateUser
      await apiConfig.updateUser({ ...userData, isPhoneVerified: true });
    } catch (err) {
      setError(err.response?.data?.message || "Failed to verify OTP");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await apiConfig.updateUser(userData);
      setSuccess("Profile updated successfully");
      setUserData(response.data);
      localStorage.setItem("phone", userData.phone);
      localStorage.setItem(
        "name",
        `${userData.firstName} ${userData.lastName}`
      );
    } catch (err) {
      setError(err.response?.data?.message || "Failed to update profile");
    } finally {
      setLoading(false);
    }
  };

  // Countdown logic
  useEffect(() => {
    if (modalOpen && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [modalOpen, countdown]);

  const resendOtp = () => {
    sendOtp();
  };

  return (
    <>
      {isMobile && <Navbar />}
      <Box
        sx={{
          marginBottom: isMobile ? "50px" : "",
          marginTop: isMobile ? "100px" : "",
          border: "1px solid #ddd",
          borderRadius: "8px",
          padding: 3,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="space-between">
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={userData.firstName}
              onChange={handleChange}
              required
              margin="normal"
              sx={{ marginRight: 1 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={userData.lastName}
              onChange={handleChange}
              required
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={userData.email}
            onChange={handleChange}
            required
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* <TextField
            fullWidth
            label="Phone Number"
            name="phone"
            type="text"
            value={userData.phone}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color={userData.isPhoneVerified ? "success" : "primary"}
                    onClick={userData.isPhoneVerified ? null : sendOtp}
                    disabled={userData.isPhoneVerified || verifying}
                    sx={{
                      textTransform: "none",
                      backgroundColor: userData.isPhoneVerified
                        ? "green"
                        : "black",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#333",
                      },
                    }}
                  >
                    {userData.isPhoneVerified
                      ? "Verified"
                      : verifying
                      ? "Verifying..."
                      : "Verify"}
                  </Button>
                </InputAdornment>
              ),
            }}
            disabled={userData.isPhoneVerified}
          /> */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{
              marginTop: 2,
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
          >
            {loading ? "Updating..." : "Update Profile"}
          </Button>
        </form>

        {/* OTP Verification Modal */}
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
          <DialogContent>
            <Typography variant="h6" sx={{ fontSize: "16px", marginBottom: 2 }}>
              Verify Phone Number
            </Typography>
            <TextField
              fullWidth
              label="Enter OTP"
              name="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography variant="body2" color="textSecondary">
              {countdown > 0
                ? `Resend OTP in ${Math.floor(countdown / 60)}:${
                    countdown % 60
                  }`
                : "You can now resend OTP"}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={resendOtp}
              disabled={countdown > 0}
              color="primary"
            >
              Resend OTP
            </Button>
            <Button
              onClick={verifyOtp}
              disabled={!otp}
              variant="contained"
              sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
            >
              Verify OTP
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={!!error}
          autoHideDuration={4000}
          onClose={() => setError("")}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={() => setError("")} severity="error">
            {error}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!success}
          autoHideDuration={4000}
          onClose={() => setSuccess("")}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={() => setSuccess("")} severity="success">
            {success}
          </Alert>
        </Snackbar>
      </Box>
      {isMobile && <Footer />}
    </>
  );
};

export default Profile;
