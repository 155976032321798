import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const QuantitySelectorModal = ({ open, onClose, onConfirm }) => {
  const [selectedSize, setSelectedSize] = useState("");

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      sx={{ padding: "15px" }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", padding: 0, fontSize: "12px", margin: 0 }}
        >
          Select Quantity
        </DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
        >
          <CloseIcon sx={{ fontSize: "18px" }} />
        </IconButton>
      </Box>
      <DialogContent sx={{ textAlign: "center" }}>
        <Box
          display="flex"
          justifyContent="center"
          gap="6px" // Reduced gap between buttons
          flexWrap="wrap"
        >
          {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map((size) => (
            <Button
              key={size}
              variant={selectedSize === size ? "contained" : "outlined"}
              onClick={() => handleSizeSelect(size)}
              sx={{
                width: "30px", // Smaller width
                height: "35px", // Smaller height
                padding: "0", // Reduced padding
                borderRadius: "6px", // Adjusted border radius
                fontSize: "12px", // Smaller font size
                fontWeight: "bold",
                borderColor: selectedSize === size ? "black" : "#ccc",
                backgroundColor: selectedSize === size ? "black" : "white",
                color: selectedSize === size ? "white" : "#333",
                "&:hover": {
                  backgroundColor: selectedSize === size ? "black" : "#f5f5f5",
                },
              }}
            >
              {size}
            </Button>
          ))}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          padding: "15px 40px",
        }}
      >
        <Button
          variant="contained"
          fullWidth
          onClick={() => onConfirm(selectedSize)}
          disabled={!selectedSize}
          sx={{
            backgroundColor: "black",
            color: "white",
            fontWeight: "bold",
            padding: "10px",
            fontSize: "16px",
            height: "50px",
            "&:hover": {
              backgroundColor: "#333",
            },
            borderRadius: "8px",
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
