// src/components/Slider.js
import React, { useEffect, useState, useMemo } from "react";
import "./css/slider.css";
import { apiConfig } from "../utils/apiConfig";
import { SliderSkeleton } from "./skeleton/SliderSkeleton";

const slidesData = [{ id: 1 }, { id: 2 }, { id: 3 }];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideData, setSlideData] = useState([]);

  const [loading, setLoading] = useState(true);

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slidesData.length);
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + slidesData.length) % slidesData.length);
  };

  // Automatically move to the next slide every 5 seconds
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiConfig.getAllCarousels();
        console.log(response?.data, "Checkin");
        setSlideData(response?.data); // Assuming the API returns user data in response.data
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <>
      {loading ? (
        <SliderSkeleton />
      ) : (
        <section className="slider">
          {slideData.map((slide, index) => (
            <div
              key={slide.id}
              className={`slide ${index === currentSlide ? "active" : ""}`}
            >
              <img src={slide.imageUrl} alt={slide.text} />
              <div className="slide-text">{slide.description}</div>
            </div>
          ))}
          {/* <div className="slider-controls">
            <span className="prev" onClick={prevSlide}>
              &#10094;
            </span>
            <span className="next" onClick={nextSlide}>
              &#10095;
            </span>
          </div> */}
        </section>
      )}
    </>
  );
};

export default Slider;
