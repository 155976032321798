import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProductOverviewSkeleton = () => {
  return (
    <div className="container" style={{ marginTop: "110px" }}>
      <h3 className="mb-4">
        <Skeleton
          width={260}
          height={40}
          inline={true}
          style={{ marginRight: "8px" }}
        />
      </h3>
      <div className="row">
        <div className="col-6 col-md-6 col-lg-3 mb-4">
          <div className="product-card">
            <Skeleton
              height={250}
              width="100%"
              style={{ borderRadius: "8px" }}
            />
            <div className="mt-3">
              <Skeleton
                width="80%"
                height={20}
                style={{ marginBottom: "8px" }}
              />
              <Skeleton
                width="60%"
                height={20}
                style={{ marginBottom: "8px" }}
              />
              <div className="price-info">
                <Skeleton
                  width={40}
                  height={20}
                  inline={true}
                  style={{ marginRight: "8px" }}
                />
                <Skeleton width={40} height={20} inline={true} />
                <Skeleton
                  width={40}
                  height={20}
                  inline={true}
                  style={{ marginLeft: "8px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-6 col-md-6 col-lg-3 mb-4">
          <div className="product-card">
            <Skeleton
              height={250}
              width="100%"
              style={{ borderRadius: "8px" }}
            />
            <div className="mt-3">
              <Skeleton
                width="80%"
                height={20}
                style={{ marginBottom: "8px" }}
              />
              <Skeleton
                width="60%"
                height={20}
                style={{ marginBottom: "8px" }}
              />
              <div className="price-info">
                <Skeleton
                  width={40}
                  height={20}
                  inline={true}
                  style={{ marginRight: "8px" }}
                />
                <Skeleton width={40} height={20} inline={true} />
                <Skeleton
                  width={40}
                  height={20}
                  inline={true}
                  style={{ marginLeft: "8px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-6 col-md-6 col-lg-3 mb-4">
          <div className="product-card">
            <Skeleton
              height={250}
              width="100%"
              style={{ borderRadius: "8px" }}
            />
            <div className="mt-3">
              <Skeleton
                width="80%"
                height={20}
                style={{ marginBottom: "8px" }}
              />
              <Skeleton
                width="60%"
                height={20}
                style={{ marginBottom: "8px" }}
              />
              <div className="price-info">
                <Skeleton
                  width={40}
                  height={20}
                  inline={true}
                  style={{ marginRight: "8px" }}
                />
                <Skeleton width={40} height={20} inline={true} />
                <Skeleton
                  width={40}
                  height={20}
                  inline={true}
                  style={{ marginLeft: "8px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-6 col-md-6 col-lg-3 mb-4">
          <div className="product-card">
            <Skeleton
              height={250}
              width="100%"
              style={{ borderRadius: "8px" }}
            />
            <div className="mt-3">
              <Skeleton
                width="80%"
                height={20}
                style={{ marginBottom: "8px" }}
              />
              <Skeleton
                width="60%"
                height={20}
                style={{ marginBottom: "8px" }}
              />
              <div className="price-info">
                <Skeleton
                  width={40}
                  height={20}
                  inline={true}
                  style={{ marginRight: "8px" }}
                />
                <Skeleton width={40} height={20} inline={true} />
                <Skeleton
                  width={40}
                  height={20}
                  inline={true}
                  style={{ marginLeft: "8px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductOverviewSkeleton;
