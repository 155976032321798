import React, { useEffect, useState } from "react";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import "./css/faq.css";
import { apiConfig } from "../utils/apiConfig";
import { Helmet } from "react-helmet"; // Import react-helmet

export default function FAQ() {
  const [allFAQ, setAllFAQ] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading status

  useEffect(() => {
    const fetchFAQ = async () => {
      try {
        const response = await apiConfig.getAllFAQ(); // Replace with your actual API endpoint
        console.log(response?.data);
        setAllFAQ(response?.data); // Assuming response.data contains the array of FAQ
        setLoading(false); // Set loading to false after fetching
      } catch (err) {
        console.error("Error fetching FAQ:", err);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchFAQ();
  }, []); // Empty dependency array means this effect runs only once when component mounts

  if (loading) {
    return <p>Loading FAQ...</p>;
  }

  return (
    <>
      <Helmet>
        <title>FAQ - TheMonkeySoul</title>
        <meta
          name="description"
          content="Your questions answered. Discover everything you need to know about TheMonkeySoul."
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://themonkeysoul.com/contact-us/faq" />
      </Helmet>

      <Navbar />

      <div
        className="container"
        style={{ marginTop: "110px", marginBottom: "50px" }}
      >
        <div className="row">
          <div className="col-md-12">
            <h1 className="product-overview">FAQ</h1>
            <p>
              <i>
                Your questions answered. Discover everything you need to know
                about TheMonkeySoul.
              </i>
            </p>
          </div>
        </div>
        <MDBAccordion initialActive={1}>
          {allFAQ?.map((item, index) => (
            <MDBAccordionItem
              collapseId={index + 1}
              headerTitle={item?.question}
            >
              {item?.answer}
            </MDBAccordionItem>
          ))}
        </MDBAccordion>
      </div>
      <Footer />
    </>
  );
}
