import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import "react-loading-skeleton/dist/skeleton.css"; // Skeleton CSS styles
import Skeleton from "react-loading-skeleton";
import "../css/add-to-cart.css";

const CartSkeleton = () => {
  return (
    <>
      <main style={{ flex: 1, marginTop: "100px" }}>
        <Box p={3} maxWidth="lg" margin="auto">
          <Typography variant="h5" gutterBottom style={{ fontWeight: 600 }}>
            <Skeleton
              width={260}
              height={40}
              inline={true}
              style={{ marginRight: "8px" }}
            />
          </Typography>
          <div className="d-none d-md-flex row mt-3">
            <Skeleton
              width={1150}
              height={300}
              inline={true}
              style={{ marginRight: "8px" }}
            />
          </div>
          <div className="d-md-none mt-3">
            <Skeleton
              width={"20rem"}
              height={300}
              inline={true}
              style={{ marginRight: "8px" }}
            />
          </div>
        </Box>
      </main>
    </>
  );
};

export default CartSkeleton;
