export const setProducts = (products) => {
  return {
    type: "SET_PRODUCTS",
    payload: products,
  };
};

export const saveProductDetails = (productName, productUrl) => ({
  type: "SAVE_PRODUCT_DETAILS",
  payload: { name: productName, url: productUrl },
});
