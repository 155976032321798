import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Navbar from "../components/NavBar";
import { Products } from "../components/Products";
import ShopByCategory from "../components/ShopByCategory";
import Slider from "../components/Slider";
import { Helmet } from "react-helmet";

export const MainPage = () => {
  return (
    <>
      <Helmet>
        <title>Discover Trendy Fashion Online - TheMonkeySoul</title>
        <meta
          name="description"
          content="Shop the latest trends in men's and women's fashion at TheMonkeySoul. Discover stylish clothing, including shirts, dresses, and more for a fashion-forward lifestyle."
        />
        <meta
          name="keywords"
          content="online shopping, trendy fashion, stylish clothing, shirts, dresses, men's fashion, women's fashion, TheMonkeySoul"
        />
        <link rel="canonical" href="https://themonkeysoul.com/" />
      </Helmet>
      <div>
        <Navbar />
        <Slider />
        <ShopByCategory />
        {/* <Banner /> */}
        <Products />
        <Footer />
      </div>
    </>
  );
};
