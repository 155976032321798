import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Navbar from "../NavBar";
import Footer from "../Footer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { formatToIST } from "../../utils/helper";
import { apiConfig } from "../../utils/apiConfig";
import Swal from "sweetalert2"; // Import SweetAlert2

const OrderDetails = () => {
  const isMobile = useMediaQuery("(max-width: 790px)");

  const location = useLocation();
  const { orderDetail } = location.state || {};

  const handleCancelOrder = async (id) => {
    console.log(id, "chekcing Id");
    // Show confirmation dialog using SweetAlert2
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to cancel this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, cancel it!",
      cancelButtonText: "No, keep it",
      reverseButtons: true,
    });

    // If the user confirms the cancellation
    if (result.isConfirmed) {
      console.log("Cancel Order");
      try {
        const response = await apiConfig.cancelOrder({ orderId: id });

        // Handle the response based on status code
        if (response.status === 201) {
          Swal.fire("Cancelled!", "Your order has been cancelled.", "success");
          window.history.back();
        } else {
          Swal.fire(
            "Error!",
            `Order has already been shipped and cannot be cancelled`,
            "error"
          );
        }
      } catch (error) {
        Swal.fire(
          "Error!",
          "An error occurred while processing your request.",
          "error"
        );
      }
    } else {
      // Optionally handle the case if the user canceled
      console.log("Order cancellation was canceled");
    }
  };

  const handleNeedHelp = () => {
    console.log("Need Help");
  };
  return (
    <>
      {isMobile && <Navbar />}
      <Box
        sx={{
          padding: isMobile ? 2 : "",
          marginTop: isMobile ? "80px" : "",
          marginBottom: isMobile ? "50px" : "",
        }}
      >
        {/* Order Summary */}
        <Box
          sx={{
            border: "1px solid #ddd",
            borderRadius: 2,
            padding: 2,
            marginBottom: 2,
          }}
        >
          <Grid container spacing={isMobile ? 1 : 2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" fontWeight="bold">
                Order Number
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                textTransform="uppercase"
              >
                {orderDetail?._id || "92150057"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" fontWeight="bold">
                Order Placed
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatToIST(orderDetail?.createdAt)?.split("at")[0]}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Product Details */}
        {orderDetail?.products?.map((item, index) => (
          <Box
            key={index}
            sx={{
              border: "1px solid #ddd",
              borderRadius: 2,
              padding: 2,
              marginBottom: 2,
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={2}>
                <Box
                  sx={{
                    width: "80%",
                    borderRadius: 1,
                    border: "1px solid #ddd",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={
                      item?.product?.primaryImageUrl ||
                      "https://via.placeholder.com/150"
                    }
                    alt="Product"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: 8,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={8} sm={10}>
                <p style={{ margin: "0", fontWeight: "600" }}>
                  TheMonkeySoul(™)
                </p>
                <p style={{ margin: "0", fontSize: "13px", color: "#676767" }}>
                  {item?.product?.productName}
                </p>
                <p style={{ margin: "0", fontSize: "13px", color: "#676767" }}>
                  Size: {item?.size || "N/A"}
                </p>
                <p style={{ margin: "0", fontSize: "13px", color: "#676767" }}>
                  Price: ₹{item?.product?.sellingPrice}
                </p>
              </Grid>
            </Grid>
          </Box>
        ))}

        {/* Order Delivery Status */}
        <Box
          sx={{
            border: "1px solid #ddd",
            borderRadius: 2,
            padding: 2,
            marginBottom: 2,
          }}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              {orderDetail.paymentStatus === "failed" ||
              orderDetail.orderStatus === "cancelled" ? (
                <ErrorIcon color="error" fontSize="large" />
              ) : (
                <CheckCircleIcon color="success" fontSize="large" />
              )}
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                color={
                  orderDetail.paymentStatus === "failed" ||
                  orderDetail.orderStatus === "cancelled"
                    ? "error.main"
                    : "success.main"
                }
                fontWeight="bold"
                textTransform="uppercase"
              >
                {orderDetail.paymentStatus === "failed"
                  ? "Order Failed"
                  : `${
                      orderDetail.orderStatus === "pending"
                        ? "Order Placed"
                        : orderDetail.orderStatus
                    }`}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {orderDetail.paymentStatus === "failed"
                  ? "Please try again later."
                  : `On ${formatToIST(
                      orderDetail?.deliveryDate || "2024-10-17"
                    )}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Shipping Address */}
        <Box
          sx={{
            border: "1px solid #ddd",
            borderRadius: 2,
            padding: 2,
          }}
        >
          <Typography
            variant="body2"
            fontWeight="bold"
            gutterBottom
            textTransform="uppercase"
          >
            Shipping Address
          </Typography>
          <Typography variant="body2">
            <strong>{`${orderDetail?.address?.firstName} ${orderDetail?.address?.lastName}`}</strong>{" "}
            | {orderDetail?.address?.phoneNumberId?.number || "918178439219"}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {orderDetail?.address?.street ||
              "Gali 10, House no 09, Khajoor Road"}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%" // Adjust as needed
          margin="15px auto"
        >
          <Button
            variant="outlined"
            color="error"
            disabled={orderDetail?.orderStatus === "cancelled" ? true : false}
            onClick={() => handleCancelOrder(orderDetail?._id)}
            sx={{
              borderColor: "red",
              color: "red",
              "&:hover": {
                borderColor: "darkred",
                backgroundColor: "rgba(255, 0, 0, 0.1)",
              },
            }}
          >
            Cancel Order
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleNeedHelp}
            sx={{
              borderColor: "blue",
              color: "blue",
              "&:hover": {
                borderColor: "darkblue",
                backgroundColor: "rgba(0, 0, 255, 0.1)",
              },
            }}
          >
            Need Help ?
          </Button>
        </Box>
      </Box>
      {isMobile && <Footer />}
    </>
  );
};

export default OrderDetails;
