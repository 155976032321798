import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const token = localStorage.getItem('token'); // Replace 'token' with your actual token key

  // If token is not found or the role is not "customer", redirect to sign-in page
  if (!token) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  // If token exists and role is "customer", render the children (protected component)
  return children;
};

export default ProtectedRoute;
