import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  Divider,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiConfig } from "../../utils/apiConfig";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useMediaQuery } from "@mui/material";
import Navbar from "../NavBar";
import Footer from "../Footer";
import { formatToIST } from "../../utils/helper";

const StyledOrderBox = styled(Box)(({ theme }) => ({
  border: "1px solid #ddd",
  borderRadius: "8px",
  marginBottom: "16px",
  overflow: "hidden",
  backgroundColor: "#fff",
  // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
}));

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 790px)");

  const handleProductClick = (item) => {
    if (!isMobile) {
      navigate("/my-account/order/moreInfo", { state: { orderDetail: item } });
    } else {
      navigate("/my-account/mobile-view/order/moreInfo", {
        state: { orderDetail: item },
      });
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await apiConfig.getAllOrder();
        setOrders(response?.data?.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "30vh" }}>
        {isMobile && <Navbar />}
        <Box
          sx={{
            padding: isMobile ? 1.5 : "",
            marginTop: isMobile ? "80px" : "",
            marginBottom: isMobile ? "50px" : "",
          }}
        >
          {!orders || orders.length === 0 ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                color: "#666",
                padding: 3,
              }}
            >
              There are no orders yet. Start shopping now!
            </Typography>
          ) : (
            orders.map((order) => (
              <StyledOrderBox key={order.orderId}>
                <Box
                  // flex={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // backgroundColor: order.status === "Failed" ? "#ffe6e6" : "#e6f7e6",
                    padding: 2,
                  }}
                >
                  {order.paymentStatus === "failed" ||
                  order.orderStatus === "cancelled" ? (
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 2,
                        backgroundColor: "#f8d7da",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          color: "#d32f2f",
                          fontSize: "15px",
                        }}
                      >
                        x
                      </Typography>
                    </Box>
                  ) : (
                    <CheckCircleIcon
                      color="success"
                      fontSize="large"
                      sx={{ marginRight: 1 }}
                    />
                  )}

                  <div>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color:
                          order.paymentStatus === "failed" ||
                          order.orderStatus === "cancelled"
                            ? "#ee4d4f"
                            : "#388e3c",
                      }}
                    >
                      {order.paymentStatus === "failed"
                        ? "Payment Failed"
                        : `${
                            order.orderStatus === "pending"
                              ? "Order Placed"
                              : order.orderStatus
                          }`}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "13px", color: "#666" }}
                    >
                      Order placed on{" "}
                      {formatToIST(order?.createdAt)?.split("at")[0]}
                    </Typography>
                  </div>
                </Box>
                <Divider />
                {order.products.map((item, index) => (
                  <List key={index}>
                    <ListItem
                      disableGutters
                      sx={{
                        display: "flex",
                        padding: 2,
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "#f9f9f9" },
                      }}
                      onClick={() => handleProductClick(order)}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4} sm={2}>
                          <Box
                            sx={{
                              width: "80%",
                              borderRadius: 1,
                              border: "1px solid #ddd",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={item?.product?.primaryImageUrl}
                              alt={item?.product?.productName}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={8} sm={10}>
                          <p style={{ margin: "0", fontWeight: "600" }}>
                            TheMonkeySoul(™)
                          </p>
                          <p style={{ margin: "0", color: "#676767" }}>
                            {item?.product?.productName}
                          </p>
                          <p style={{ margin: "0", color: "#676767" }}>
                            Size: {item?.size || "N/A"}
                          </p>
                          <p style={{ margin: "0", color: "#676767" }}>
                            Price: ₹{item?.product?.sellingPrice}
                          </p>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                ))}
                {order.status === "Delivered" && (
                  <Box sx={{ textAlign: "center", padding: 2 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ textTransform: "none" }}
                    >
                      Rate This Product
                    </Button>
                  </Box>
                )}
              </StyledOrderBox>
            ))
          )}
        </Box>

        {isMobile && <Footer />}
      </Box>
    </>
  );
};

export default OrderHistory;
