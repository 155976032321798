import { apiConfig } from "../utils/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet"; // Import Helmet
import "./css/paymentRedirection.css"; // Import custom CSS for styling

const PaymentRedirectionPage = () => {
  const [status, setStatus] = useState("loading"); // 'loading', 'success', 'failure'
  const [message, setMessage] = useState("");
  const [redirectTimer, setRedirectTimer] = useState(3); // Countdown timer in seconds
  const navigate = useNavigate();
  const { transactionId } = useParams();

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        const response = await apiConfig.paymentStatus(transactionId);
        if (response?.data?.data?.paymentStatus === "completed") {
          setStatus("success");
          setMessage(response?.message || "Payment successful!");
        } else if (response?.data?.data?.paymentStatus === "failed") {
          setStatus("failure");
          setMessage(response.message || "Payment failed.");
        } else {
          throw new Error("Unexpected status");
        }
      } catch (error) {
        setStatus("failure");
        // setMessage("Failed to fetch payment status. Please try again.");
      }
    };

    if (transactionId) {
      fetchPaymentStatus();
    } else {
      setStatus("failure");
      setMessage("Transaction ID missing.");
    }
  }, [transactionId]);

  useEffect(() => {
    if (status === "success" || status === "failure") {
      const interval = setInterval(() => {
        setRedirectTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            navigate(status === "success" ? "/order-confirmation" : "/cart");
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval); // Cleanup timer
    }
  }, [status, navigate]);

  return (
    <>
      <Helmet>
        <title>
          {status === "success" ? "Payment Successful" : "Payment Failed"}
        </title>
        <meta
          name="description"
          content={
            status === "success"
              ? "Your payment was successful."
              : "Your payment failed. Please try again."
          }
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Navbar />
      <div className="custom-order-container" style={{ marginTop: "110px" }}>
        <div className="redirection-card">
          {status === "loading" && (
            <>
              <div className="loader"></div>
              <h2>Processing...</h2>
              <p>We are checking the status of your payment. Please wait...</p>
            </>
          )}
          {status === "success" && (
            <>
              <h2>🎉 Payment Successful!</h2>
              <p>{message}</p>
              <p>
                Redirecting you to your order confirmation in{" "}
                <strong>{redirectTimer} seconds</strong>...
              </p>
            </>
          )}
          {status === "failure" && (
            <>
              <h2>❌ Payment Failed</h2>
              <p>{message}</p>
              <p>
                Redirecting you to retry payment in{" "}
                <strong>{redirectTimer} seconds</strong>...
              </p>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentRedirectionPage;
