import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Paper,
  Avatar,
  Container,
} from "@mui/material";
import { apiConfig } from "../utils/apiConfig";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setAlertMessage("Please enter your registered email.");
      setAlertSeverity("error");
      return;
    }

    setLoading(true);

    try {
      // Simulate API request to send a password reset link
      const response = await apiConfig.forgotPassword({ email });

      // On success
      if (response.status === 200) {
        setAlertMessage(
          "A password reset link has been sent to your registered email."
        );
        setAlertSeverity("success");
      }
    } catch (error) {
      // On failure
      setAlertMessage(
        "Failed to send password reset link. Please try again later."
      );
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Full viewport height
        backgroundColor: "#f0f0f0", // Optional background color
      }}
    >
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} sx={{ padding: 3, width: "100%", maxWidth: 400 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 2, // Space below the logo
            }}
          >
            <Avatar
              alt="Company Logo"
              src="https://res.cloudinary.com/dbf9ry82l/image/upload/v1731868373/logo_l1wr4y.png" // Replace with your logo path
              sx={{
                width: 100,
                height: 100,
                objectFit: "contain", // Ensures the image fits inside the Avatar container without distortion
              }}
            />
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              textAlign: "center",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <Typography variant="h5" component="h1" gutterBottom>
              Forgot Password
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please enter your registered email.
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {alertMessage && (
              <Alert severity={alertSeverity} sx={{ marginBottom: 2 }}>
                {alertMessage}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "black",
                color: "white",
                marginTop: 2,
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default ForgotPassword;
