import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import cartReducer from "./reducers/cartReducer";
import productReducer from "./reducers/productReducer";

// Combine Reducers
const rootReducer = combineReducers({
  cart: cartReducer,
  products: productReducer,
});

// Create Store
const store = createStore(rootReducer, composeWithDevTools());

export default store;
