import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Rating,
  LinearProgress,
  Button,
  useTheme,
} from "@mui/material";
import { apiConfig } from "../utils/apiConfig";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ProductReviews = ({ productId, productSlug }) => {
  const [ratingsData, setRatingsData] = useState({
    averageRating: 4.5,
    totalRatings: 461,
    starCounts: {
      5: 269,
      4: 136,
      3: 56,
      2: 0,
      1: 0,
    },
    recommendPercentage: 88,
  });
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme(); // Get theme for breakpoints

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        // Fetch ratings data from backend
        const response = await apiConfig.getRatingByProductId(productId);
        console.log(response.data, "checking");
        if (response?.data?.status) {
          setRatingsData({});
          setIsLoading(false);
          return;
        }
        setRatingsData(response?.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching ratings:", error);
        setIsLoading(false);
      }
    };

    fetchRatings();
  }, [productId]);

  if (isLoading) return <Typography>Loading...</Typography>;

  // Safely destructure ratingsData with fallback default values
  const {
    averageRating = 0,
    totalRatings = 0,
    starCounts = {},
    recommendPercentage = 0,
  } = ratingsData || {};

  // if (!ratingsData || Object.keys(ratingsData).length === 0) {
  //   return (
  //     <Typography sx={{ fontSize: "18px", textAlign: "center" }}>
  //       No ratings available for this product.
  //     </Typography>
  //   );
  // }

  const getProgressColor = (rating) => {
    switch (rating) {
      case 5:
        return "#4caf50"; // Green
      case 4:
        return "#8bc34a"; // Light green
      case 3:
        return "#cddc39"; // Lighter green
      case 2:
        return "#ffeb3b"; // Pale yellow
      case 1:
        return "#ffc107"; // Very pale yellow
      default:
        return "#ddd"; // Default
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 700,
        margin: "auto",
        padding: 2,
        backgroundColor: "#fff",
        borderRadius: 2,
        border: "1px solid #ddd",
        [theme.breakpoints.down("sm")]: {
          padding: 1, // Less padding on small screens
        },
      }}
    >
      {/* Header */}
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          borderBottom: "2px solid #f0c12c",
          pb: 1,
          fontSize: { xs: "1.25rem", sm: "1.5rem" }, // Adjust font size for mobile
        }}
      >
        Product Reviews
      </Typography>

      {/* Recommendation Section */}
      <Typography
        variant="body1"
        sx={{
          color: "#666",
          mb: 3,
          fontSize: { xs: "0.875rem", sm: "1rem" }, // Adjust font size for mobile
        }}
      >
        {/* {recommendPercentage}% of verified buyers recommend this product */}
      </Typography>

      {/* Main Review Content */}
      <Box
        display="flex"
        gap={4}
        sx={{
          flexDirection: { xs: "column", sm: "row" }, // Stack items vertically on small screens
        }}
      >
        {/* Average Rating */}
        <Box
          textAlign="center"
          flex={1}
          sx={{
            mb: { xs: 3, sm: 0 },
            display: "flex", // Enable flexbox
            flexDirection: { xs: "column" }, // Stack vertically on mobile, inline on larger screens
            justifyContent: "center",
            alignItems: "center",
            gap: 1, // Add space between the button and rating
          }}
        >
          {/* Average Rating */}
          <Typography
            variant="h3"
            color="textPrimary"
            sx={{
              fontSize: { xs: "2rem", sm: "3rem" },
            }}
          >
            {averageRating?.toFixed(1)}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              fontSize: { xs: "0.875rem", sm: "1rem" },
            }}
          >
            {totalRatings} ratings
          </Typography>

          {/* Star Rating */}
          <Rating value={averageRating} precision={0.5} readOnly size="large" />

          {/* Rate Button */}
          <Button
            variant="outlined" // Transparent background with border
            component={Link} // Use Link for routing
            to={`/product-rate/${productId}/${productSlug}`} // Destination route
            sx={{
              color: "#000", // Black text
              borderColor: "#000", // Black border
              width: { xs: "50%", sm: "auto" }, // 50% width on mobile, auto width on larger screens
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)", // Hover effect
                borderColor: "#000",
              },
            }}
          >
            RATE
          </Button>
        </Box>

        {/* Rating Breakdown */}
        <Box flex={2}>
          {Object?.entries(starCounts)
            ?.sort((a, b) => b[0] - a[0]) // Sort by star rating descending
            ?.map(([star, count]) => (
              <Box key={star} display="flex" alignItems="center" mb={1}>
                <Typography
                  variant="body2"
                  sx={{
                    minWidth: 50,
                    fontSize: { xs: "0.875rem", sm: "1rem" }, // Adjust font size for mobile
                  }}
                >
                  {star} ★
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={(count / totalRatings) * 100}
                  sx={{
                    flexGrow: 1,
                    mx: 2,
                    height: 8,
                    borderRadius: 1,
                    backgroundColor: "#ddd",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: getProgressColor(Number(star)),
                    },
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    minWidth: 40,
                    textAlign: "right",
                    fontSize: { xs: "0.75rem", sm: "1rem" }, // Adjust font size for mobile
                  }}
                >
                  ({count})
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
      {/* <Box>
        <h5>Comments</h5>
      </Box> */}
    </Box>
  );
};

export default ProductReviews;
