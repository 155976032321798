import React, { useEffect, useState } from "react";
import "./css/navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { apiConfig } from "../utils/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import { setCartItems } from "../store/actions/cartActions";
import { useMediaQuery } from "@mui/material";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false); // State to track mobile view
  const [scrolled, setScrolled] = useState(false);
  const dispatch = useDispatch();
  const [cartCount, setCartCount] = useState(0);
  const location = useLocation();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const isHomePage = location.pathname === "/";
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem("token"); // Check if token exists
  const totalItem = useSelector((state) => state.cart.totalQuantity);
  const toggleMenu = () => {
    setIsMobile(!isMobile);
    setIsPanelOpen(!isPanelOpen);
  };
  const isMobileView = useMediaQuery("(max-width: 790px)");

  const handleMyAccount = () => {
    if (isLoggedIn) {
      navigate("/my-account"); // If logged in, navigate to My Account page
    } else {
      const currentPath = window.location.pathname;
      navigate(`/sign-in?redirect=${encodeURIComponent(currentPath)}`);
    }
  };

  const handleCart = () => {
    if (isLoggedIn) {
      navigate("/cart"); // If logged in, navigate to Cart page
    } else {
      const currentPath = window.location.pathname;
      navigate(`/sign-in?redirect=${encodeURIComponent(currentPath)}`);
    }
  };

  // Function to fetch initial cart data from API
  const fetchCartData = async () => {
    try {
      const response = await apiConfig.allItems();
      // Assuming the API response contains a list of cart items
      const cartItems = response?.data?.data || [];
      const totalAmount = cartItems.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
      // Update local state
      setCartCount(cartItems.length);
      // Dispatch action to update Redux store
      dispatch(setCartItems(cartItems, totalAmount));
    } catch (error) {
      console.error("Failed to fetch cart data:", error);
      setCartCount(0);
      dispatch(setCartItems([], 0)); // Clear cart in Redux on error
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchCartData();
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isPanelOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isPanelOpen]);

  const boxStyle = {
    boxShadow: isHomePage ? "none" : "0 2px 5px rgba(0, 0, 0, 0.1)",
  };

  return (
    <header>
      <nav
        style={boxStyle}
        className={`navbar ${isMobile ? "mobile active" : ""} ${
          scrolled ? "scrolled" : ""
        }`}
      >
        <span className="hamburger-icon" onClick={toggleMenu}>
          {/* <i class="fa-solid fa-bars-staggered"></i> */}
        </span>
        <Link to={"/"} className="logo-nav">
          {!isMobileView ? (
            // https://res.cloudinary.com/dbf9ry82l/image/upload/v1732205174/typography_csydyv.png
            // <div className="logo">TheMonkeySoul</div>
            <div className="logo-desktop">
              <img
                width="100%"
                height="auto"
                src="https://res.cloudinary.com/dbf9ry82l/image/upload/v1732211254/5_hhj9f6.png"
                alt="logo"
              />
            </div>
          ) : (
            <div className="logo">
              <img
                style={{ width: 45, height: 40 }}
                src="https://res.cloudinary.com/dbf9ry82l/image/upload/v1731868374/logo_2_kzihjg.png"
                alt="logo"
              />
            </div>
          )}
        </Link>

        {/* User and Cart icons in mobile view */}
        {isMobile && (
          <div className="navbar-icons">
            <span className="icon" onClick={handleMyAccount}>
              <i className="fa-solid fa-user"></i>
              {isLoggedIn ? " You" : " Login"}
            </span>
            <span className="icon" onClick={handleCart}>
              <i className="fas fa-shopping-cart"></i>
              <span id="lblCartCount">{cartCount}</span>
            </span>
          </div>
        )}

        {/* Main navigation links */}
        {!isMobile && (
          <ul
            className={`nav-links ${isMobile ? "show" : ""}`}
            style={{ textTransform: "uppercase" }}
          >
            <li style={{ fontSize: "large" }}>
              <Link to={"/"}>Home</Link>
            </li>
            <li style={{ fontSize: "large" }}>
              <Link to={"/product"}>Shop</Link>
            </li>
            <li style={{ fontSize: "large" }}>
              <Link to={"/blog"}>Blog</Link>
            </li>
          </ul>
        )}

        {/* User and Cart icons in desktop view */}
        {!isMobile && (
          <div className="navbar-icons">
            <span
              className="icon"
              onClick={handleMyAccount}
              style={{ margin: "0" }}
            >
              <Link to="">
                <i className="fa-solid fa-user"></i>
                {isLoggedIn ? " You" : " Login"}
              </Link>
            </span>
            <span className="icon" onClick={handleCart} style={{ margin: "0" }}>
              <i className="fas fa-shopping-cart"></i>
              <span id="lblCartCount">{totalItem}</span>
            </span>
          </div>
        )}
        {/* Sliding Overlay Panel */}
        <div className={`overlay-panel ${isPanelOpen ? "open" : ""}`}>
          <div className="overlay-content">
            <span className="close-icon" onClick={toggleMenu}>
              &times;
            </span>
            <Link to={"/product"} onClick={toggleMenu}>
              Shop
            </Link>
            <Link to={"/blog"} onClick={toggleMenu}>
              Blog
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
