import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Paper,
  Avatar,
  Container,
} from "@mui/material";
import { apiConfig } from "../utils/apiConfig";

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams(); // To get query parameters (token)
  const navigate = useNavigate();

  const token = searchParams.get("token");

  useEffect(() => {
    // If no token is found in URL, redirect to login or show error
    if (!token) {
      setError("Invalid or expired reset link.");
    }
  }, [token]);

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setLoading(true);

    try {
      // Send the reset password request to the backend
      const response = await apiConfig.resetPassword({
        token,
        newPassword,
      });

      setSuccess(response.data.message);
      setError(null);
      // Redirect to login page after successful password reset
      setTimeout(() => navigate("/sign-in"), 2000);
    } catch (err) {
      setError(err.response ? err.response.data.message : "Server error");
      setSuccess(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Full viewport height
        backgroundColor: "#f0f0f0", // Optional background color
      }}
    >
      <Container component="main" maxWidth="xs">
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            width: "100%",
            maxWidth: 400,
            boxSizing: "border-box",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 2, // Space below the logo
            }}
          >
            <Avatar
              alt="Company Logo"
              src="https://res.cloudinary.com/dbf9ry82l/image/upload/v1731868373/logo_l1wr4y.png" // Replace with your logo path
              sx={{
                width: 100,
                height: 100,
                objectFit: "contain", // Ensures the image fits inside the Avatar container without distortion
              }}
            />
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handlePasswordReset}
            sx={{ textAlign: "center" }}
          >
            <Typography variant="h5" component="h1" gutterBottom>
              Reset Your Password
            </Typography>
            {error && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ marginBottom: 2 }}>
                {success}
              </Alert>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="newPassword"
              label="New Password"
              name="newPassword"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              autoFocus
              sx={{
                marginBottom: 2,
                width: "100%",
              }}
              inputProps={{ minLength: 6 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{
                marginBottom: 2,
                width: "100%",
              }}
              inputProps={{ minLength: 6 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "black",
                color: "white",
                marginTop: 2,
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
              disabled={loading}
            >
              {loading ? "Submitting..." : "Reset Password"}
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default ResetPasswordPage;
