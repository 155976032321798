import React from "react";
import { Helmet } from "react-helmet"; // Import react-helmet
import "./css/paymentFailure.css"; // Import custom CSS for styling
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const PaymentFailure = () => {
  return (
    <>
      <Helmet>
        <title>Payment Failed - TheMonkeySoul</title>
        <meta
          name="description"
          content="Your payment attempt has failed. Please check your payment details and try again, or contact support for assistance."
        />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://themonkeysoul.com/payment-failed" />
      </Helmet>

      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Navbar />
        <div
          className="custom-order-container"
          style={{ flex: 1, marginTop: "110px", overflow: "hidden" }}
        >
          <div className="failure-card">
            <h2>❌ Payment Failed</h2>
            <h4>Unfortunately, your payment was not successful.</h4>
            <p>
              Please check your payment details and try again. If the issue
              persists, contact our support team for assistance.
            </p>
            <Link to={"/cart"} className="btn-retry">
              Retry Payment
            </Link>
          </div>
        </div>
        <Footer />
      </Box>
    </>
  );
};

export default PaymentFailure;
