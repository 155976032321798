import {
  Box,
  Card,
  Typography,
  Button,
  Chip,
  Snackbar,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiConfig } from "../../utils/apiConfig";
import Swal from "sweetalert2";
import Navbar from "../NavBar";
import Footer from "../Footer";
import { useMediaQuery } from "@mui/material";

const AddressManager = () => {
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const isMobile = useMediaQuery("(max-width: 790px)");

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await apiConfig.getAddressesByUser(); // Adjust the endpoint as needed
        setAddresses(response.data.data);
      } catch (err) {
        console.log(err, "Error");
      }
    };

    fetchAddresses();
  }, []);

  const handleEdit = (id) => {
    if (!isMobile) {
      navigate(`/my-account/addresses/add`, { state: { id } });
    } else {
      navigate(`/my-account/mobile-view/addresses/add`, { state: { id } });
    }
    // Navigate to the edit page, passing the address id as a state parameter
  };

  const handleRemove = async (id, phoneId) => {
    console.log(id, "checking");
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This address will be removed from your saved addresses.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
      cancelButtonText: "No, keep it",
    });

    if (result.isConfirmed) {
      try {
        Swal.fire(
          "Removed!",
          "The address has been removed from your saved addresses.",
          "success"
        );
        // Remove the address from the local state or Redux store if needed
        setAddresses(addresses.filter((item) => item._id !== id));
        // Make API call to delete the address from the backend/database
        await apiConfig.deleteAddress(id);
        await apiConfig.deletePhone(phoneId);
        // await apiConfig.pho
        // Optionally show success message
      } catch (error) {
        // Optionally handle errors
        Swal.fire("Error", "Something went wrong. Please try again.", "error");
      }
    } else {
      // Optionally show a cancellation message
      Swal.fire("Cancelled", "Your address is still saved.", "info");
    }
  };

  return (
    <>
      {isMobile && <Navbar />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginBottom: isMobile ? "50px" : "",
          marginTop: isMobile ? "100px" : "", // Fix: Conditional outside the object
          gap: 2,
        }}
      >
        {addresses.map((address, index) => (
          <>
            <Card
              sx={{
                width: "50%",
                padding: "20px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                boxShadow: "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  {address.firstName} {address.lastName}
                </Typography>
                <Chip
                  label={`${address.addressType}`}
                  size="small"
                  sx={{
                    backgroundColor: "#fffae6",
                    color: "#ff9800",
                    fontWeight: "bold",
                  }}
                />
              </Box>
              <Typography variant="body2" color="text.secondary">
                {`${address.street}, ${address.city}, ${address.state}, ${address.postalCode}`}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginTop: 1 }}
              >
                Mobile: {address?.phoneNumberId?.number}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: 2,
                  marginTop: "15px",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handleEdit(address._id)}
                  sx={{
                    textTransform: "none",
                    borderColor: "#3f51b5",
                    color: "#3f51b5",
                    "&:hover": {
                      borderColor: "#303f9f",
                      backgroundColor: "rgba(63, 81, 181, 0.1)",
                    },
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  onClick={() =>
                    handleRemove(address._id, address?.phoneNumberId?._id)
                  }
                  sx={{
                    textTransform: "none",
                    borderColor: "#f44336",
                    color: "#f44336",
                    "&:hover": {
                      borderColor: "#d32f2f",
                      backgroundColor: "rgba(244, 67, 54, 0.1)",
                    },
                  }}
                >
                  Remove
                </Button>
              </Box>
            </Card>
          </>
        ))}
      </Box>
      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          marginBottom: "30px",
        }}
      >
        <Button
          variant="contained"
          onClick={() =>
            navigate(
              isMobile
                ? "/my-account/mobile-view/addresses/add"
                : "/my-account/addresses/add"
            )
          }
          sx={{
            backgroundColor: "#000",
            color: "white",
            textTransform: "none",
            width: "50%",
            height: "40px",
            "&:hover": {
              backgroundColor: "#333",
            },
          }}
        >
          Add New Address
        </Button>

        {/* Notification messages */}
      </Box>
      {isMobile && <Footer />}
    </>
  );
};

export default AddressManager;
