import React from "react";
import "react-loading-skeleton/dist/skeleton.css"; // Skeleton CSS styles
import Skeleton from "react-loading-skeleton";

export const ShopByCategorySkeleton = () => {
  const categories = [
    {
      id: 1,
      name: "Oversized T-shirts",
      imgSrc:
        "https://images.bewakoof.com/uploads/grid/app/444x666-Trending-Category-Icon-Desktop--1--1729005516.jpg",
    },
    {
      id: 2,
      name: "Hoodies",
      imgSrc:
        "https://images.bewakoof.com/uploads/grid/app/444x666-Trending-Category-Icon-Desktop-11-1729005533.jpg",
    },
    {
      id: 3,
      name: "Classic Fit T-shirts",
      imgSrc:
        "https://images.bewakoof.com/uploads/grid/app/444x666-Trending-Category-Icon-Desktop-6-1729005517.jpg",
    },
    {
      id: 4,
      name: "Sweatshirts",
      imgSrc:
        "https://images.bewakoof.com/uploads/grid/app/444x666-Trending-Category-Icon-desktop-7-1729005517.jpg",
    },
    {
      id: 5,
      name: "Joggers",
      imgSrc:
        "https://images.bewakoof.com/uploads/grid/app/444x666-Trending-Category-Icon-Classic-Fit-Tshirts-3-1729005515.jpg",
    },
    {
      id: 6,
      name: "Jeans",
      imgSrc:
        "https://images.bewakoof.com/uploads/grid/app/444x666-Trending-Category-Icon-Desktop-Fullsleeve-T-shirts-Men-1729595808.jpg",
    },
  ];

  return (
    <>
      <div className="container my-5">
        <h2 className="mb-4">
          <Skeleton
            width={260}
            height={40}
            inline={true}
            style={{ marginRight: "8px" }}
          />
        </h2>
        <div className="d-none d-md-flex row mt-3">
          {categories.map((category) => (
            <div
              key={category.id}
              className="col-md-4 col-lg-2 mb-4 text-center"
            >
              <Skeleton width={216} height={324} />
              {/* <img
                src={category.imgSrc}
                alt={category.name}
                className="img-fluid rounded"
              /> */}
              {/* <p className="mt-2 fw-bold">{category.name}</p> */}
            </div>
          ))}
        </div>
        <div className="d-md-none mt-3">
          <div className="scroll-container">
            {categories.map((category) => (
              <div key={category.id} className="scroll-item text-center">
                <Skeleton width={150} height={225} />
                {/* <img
                  src={category.imgSrc}
                  alt={category.name}
                  className="img-fluid rounded"
                /> */}
                {/* <p className="mt-2 fw-bold">{category.name}</p> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
