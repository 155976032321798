import React from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import "./css/policy.css";
import { Helmet } from "react-helmet";

const ShippingPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Shipping Policy - TheMonkeySoul</title>
        <meta
          name="description"
          content="Learn about the Shipping Policy at TheMonkeySoul. Find details about delivery timelines, shipping charges, and regions we serve."
        />
        <meta
          name="keywords"
          content="shipping policy, delivery information, shipping charges, TheMonkeySoul, delivery timelines, order tracking"
        />
        <link
          rel="canonical"
          href="https://themonkeysoul.com/shipping-policy"
        />
      </Helmet>
      <Navbar />
      <div
        className="container"
        style={{ marginTop: "110px", marginBottom: "50px" }}
      >
        <h5>Shipping Policy</h5>
        <p>
          At TheMonkeySoul, we take great care in delivering your orders through
          registered domestic courier companies and/or speed post services.
          Please review our shipping process below.
        </p>

        <h6>Shipping Timeline</h6>
        <p>
          Orders are shipped within <strong>7 days</strong> from the date of
          order and/or payment, or as per the delivery date agreed upon at the
          time of order confirmation. The delivery of the shipment will be
          subject to the norms of the courier company or post office.
          TheMonkeySoul will not be liable for any delays in delivery caused by
          the courier company or postal authority.
        </p>

        <h6>Delivery Address</h6>
        <p>
          Delivery of all orders will be made to the address provided by the
          buyer at the time of purchase. It is the responsibility of the buyer
          to ensure the accuracy of the provided address.
        </p>

        <h6>Shipping Confirmation</h6>
        <p>
          Once your order has been shipped, the delivery details will be sent to
          the email address you specified during registration. You will receive
          a shipping confirmation email with tracking information (if
          available).
        </p>

        <h6>Shipping Costs</h6>
        <p>
          If there are any shipping costs levied by the seller or TheMonkeySoul
          (as the case may be), those costs are non-refundable.
        </p>

        <p>
          If you have any further questions regarding our shipping policy,
          please feel free to contact our customer support team at{" "}
          <a href="mailto:support@themonkeysoul.com">
            support@themonkeysoul.com
          </a>
          .
        </p>
      </div>
      <Footer />
    </>
  );
};

export default ShippingPolicy;
